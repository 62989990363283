@layer reset {

  *,
  *::before,
  *::after {
    background-repeat: no-repeat;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  *::before,
  *::after {
    text-decoration: inherit;
    vertical-align: inherit;
  }

  html,
  body {
    max-width: 100vw;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1em;
  }

  ol,
  ul {
    list-style: none;
  }

  :-webkit-any-link {
    color: inherit;
    text-decoration: none;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
  }

  :any-link,
  :hover,
  :active,
  :focus,
  :visited {
    color: inherit;
    text-decoration: none;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
  }

  b,
  strong {
    font-weight: 700;
  }

  ::selection {
    background-color: rgb(var(--selection-bg));
    color: rgb(var(--selection-fg));
    text-shadow: none;
  }

  img {
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  svg {
    fill: currentColor;
  }

  button {
    all: unset;
  }

  button,
  input,
  optgroupselect,
  textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
  }

  input,
  button,
  select,
  textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
  }

  :focus:focus:not(:focus-visible) {
    outline: none;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  fieldset {
    border: 0;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea,
  [tabindex] {
    touch-action: manipulation;
  }
}

:root{--x11a6b6e:0.25rem;--xx6uh78:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";}
:root{--x197wjxo:0.4375rem;--x115ck4u:0.5rem;--x1u2agcv:0.5625rem;--xywn8ae:0.6875rem;--x1kw1fl2:0.75rem;--x5bzz5b:0.875rem;--xe2w5p4:1rem;--x1ufzsyk:1.125rem;--x7ffph1:1.3125rem;--x8ybgp0:1.5rem;--x14eqgi1:1.75rem;--xj1r1em:2rem;--x1bgo7ov:2.3125rem;--x1d5j4h4:2.625rem;--x16n6y39:3.0625rem;--xjury5h:3.5rem;--x1yq3nl9:4rem;--x18yas8p:4.625rem;--xzym8rk:5.25rem;--x17sztbi:6.0625rem;--x18kb0ji:6.9375rem;--xm6exa8:8rem;--xn6a9uv:0.5rem;--x6l9bo4:0.75rem;--x9n2qkf:0.75rem;--x9uhrtx:1rem;--x1my5hdh:1.25rem;--xzhibpf:1.25rem;--xveqbg:1.5rem;--xpeiiyp:1.75rem;--x1yqsqwu:2rem;--xbv692h:2rem;--xlgulcz:2.25rem;--x6xkoxf:2.5rem;--x11g7vah:3rem;--x1omkpnl:3.25rem;--x1lgoayu:3.75rem;--xo5bj30:4rem;--x1gtk5zd:4.5rem;--x1tthiwz:5.25rem;--x16t1h6y:5.75rem;--xm3agar:6.75rem;--xpawzw4:7.5rem;--xtgxusi:8.5rem;}
:root{--xijype0:scale(0.95);--xs8praj:scale(1);--x1z0wycj:scale(1.2);}
:root{--xm75uez:255,255,255;--x17deri3:250,250,249;--x1cmvkv8:45,42,40;--x1bfcbt7:165,161,159;--xhog17l:255,255,255;--xdp9xa9:28,25,23;--xvlok90:68,65,62;--x5xmmt9:100,96,94;--x1d8o6m8:94,155,124;--x16ly0dm:30,100,70;--xqvssas:135,20,20;--x1h1bbff:237, 1, 25;--x1xy1tyl:90,90,90;--xpkcjyk:250,250,249;}
@media all and (min-width:102em){:root{--x197wjxo:0.5625rem;--x115ck4u:0.6875rem;--x1u2agcv:0.75rem;--xywn8ae:0.875rem;--x1kw1fl2:1rem;--x5bzz5b:1.125rem;--xe2w5p4:1.3125rem;--x1ufzsyk:1.5rem;--x7ffph1:1.75rem;--x8ybgp0:2rem;--x14eqgi1:2.3125rem;--xj1r1em:2.625rem;--x1bgo7ov:3rem;--x1d5j4h4:3.4375rem;--x16n6y39:4rem;--xjury5h:4.5625rem;--x1yq3nl9:5.25rem;--x18yas8p:6rem;--xzym8rk:6.9375rem;--x17sztbi:7.9375rem;--x18kb0ji:9.125rem;--xm6exa8:10.5rem;--xn6a9uv:0.75rem;--x6l9bo4:1rem;--x9n2qkf:1.25rem;--x9uhrtx:1.25rem;--x1my5hdh:1.5rem;--xzhibpf:1.75rem;--xveqbg:2rem;--xpeiiyp:2rem;--x1yqsqwu:2.25rem;--xbv692h:2.5rem;--xlgulcz:3rem;--x6xkoxf:3.25rem;--x11g7vah:3.5rem;--x1omkpnl:4rem;--x1lgoayu:4.5rem;--xo5bj30:5.25rem;--x1gtk5zd:5.75rem;--x1tthiwz:6.5rem;--x16t1h6y:7.5rem;--xm3agar:8.5rem;--xpawzw4:9.75rem;--xtgxusi:11rem;}}
@media all and (min-width:80em) and (max-width:101.9375em){:root{--x197wjxo:0.5rem;--x115ck4u:0.5625rem;--x1u2agcv:0.625rem;--xywn8ae:0.75rem;--x1kw1fl2:0.875rem;--x5bzz5b:1rem;--xe2w5p4:1.125rem;--x1ufzsyk:1.3125rem;--x7ffph1:1.5rem;--x8ybgp0:1.6875rem;--x14eqgi1:1.9375rem;--xj1r1em:2.25rem;--x1bgo7ov:2.5625rem;--x1d5j4h4:3rem;--x16n6y39:3.4375rem;--xjury5h:3.9375rem;--x1yq3nl9:4.5rem;--x18yas8p:5.1875rem;--xzym8rk:5.9375rem;--x17sztbi:6.8125rem;--x18kb0ji:7.8125rem;--xm6exa8:9rem;--xn6a9uv:0.75rem;--x6l9bo4:0.75rem;--x9n2qkf:1rem;--x9uhrtx:1.25rem;--x1my5hdh:1.25rem;--xzhibpf:1.5rem;--xveqbg:1.75rem;--xpeiiyp:2rem;--x1yqsqwu:2rem;--xbv692h:2.25rem;--xlgulcz:2.5rem;--x6xkoxf:2.75rem;--x11g7vah:3.25rem;--x1omkpnl:3.5rem;--x1lgoayu:4rem;--xo5bj30:4.5rem;--x1gtk5zd:5rem;--x1tthiwz:5.75rem;--x16t1h6y:6.5rem;--xm3agar:7.5rem;--xpawzw4:8.5rem;--xtgxusi:9.5rem;}}
.x1lqw3ck{--brdr:rgba(var(--xqvssas),.3)}
.x1ee2ish{--hdr-gutter:calc(var(--x11a6b6e) * 6)}
.xsgnb8d{--hdr-top-padding:calc(var(--x11a6b6e) * 4)}
.xcbxifo{--msg-color:var(--x16ly0dm)}
.x1dyszhm{--msg-color:var(--xqvssas)}
.x16ej6xr{--selection-bg:var(--x1xy1tyl)}
.x62bm8g{--selection-fg:var(--xpkcjyk)}
.x188243n{--y-offset:55%}
.xn1rg7a{--y-offset2:calc(var(--y-offset) - 100%)}
@keyframes xlkayg4-B{0%{transform:translateX(-100%);}50%{transform:translateX(100%);}100%{transform:translateX(-100%);}}
.xo7cbnq:focus-within{--brdr:rgb(var(--xqvssas))}
.x1bv7oh6:hover{--brdr:rgb(var(--xqvssas))}
@media screen and (orientation: landscape){.x1w5jyg7.x1w5jyg7{--hdr-top-padding:calc(var(--x11a6b6e) * 8)}}
.xtqiwn3:not(#\#){margin:0 -1px -1px 0}
.x1717udv:not(#\#){padding:0}
.x1x0x8nr:not(#\#){padding:calc(var(--x11a6b6e) * 4 - 1px)}
.x12484hp:not(#\#):not(#\#){border-color:rgb(var(--msg-color))}
.xje6vyt:not(#\#):not(#\#){border-color:rgb(var(--x1bfcbt7))}
.x9q0tmx:not(#\#):not(#\#){border-color:rgb(var(--x1cmvkv8))}
.x19tjqk1:not(#\#):not(#\#){border-color:rgba(var(--x16ly0dm),.3)}
.x16zud62:not(#\#):not(#\#){border-color:var(--brdr)}
.xn5hx6u:not(#\#):not(#\#){border-radius:15px}
.x16rqkct:not(#\#):not(#\#){border-radius:50%}
.x1dwwi7o:not(#\#):not(#\#){border-radius:calc(var(--x11a6b6e) * 2)}
.x4dp3m1:not(#\#):not(#\#){border-radius:calc(var(--x11a6b6e) * 4)}
.x19wg8vp:not(#\#):not(#\#){border-radius:var(--x11a6b6e)}
.x1y0btm7:not(#\#):not(#\#){border-style:solid}
.xc342km:not(#\#):not(#\#){border-width:0}
.xmkeg23:not(#\#):not(#\#){border-width:1px}
.xdh2fpr:not(#\#):not(#\#){border-width:2px}
.xs6hwqx:not(#\#):not(#\#){list-style:disc}
.x1a2a7pz:not(#\#):not(#\#){outline:none}
.xb3r6kr:not(#\#):not(#\#){overflow:hidden}
.x1qi1eyq:not(#\#):not(#\#){padding-block:calc(var(--x11a6b6e) - 2px)}
.xqm1x89:not(#\#):not(#\#){padding-block:calc(var(--x11a6b6e) * 1.5 - 1px)}
.xfjaua4:not(#\#):not(#\#){padding-block:calc(var(--x11a6b6e) * 2 - 1px)}
.x1i538c1:not(#\#):not(#\#){padding-block:calc(var(--x11a6b6e) * 4 - 1px)}
.xhv67ks:not(#\#):not(#\#){padding-inline:calc(var(--x11a6b6e) * 2)}
.xq2xh5n:not(#\#):not(#\#){padding-inline:calc(var(--x11a6b6e) * 4 - 1px)}
.xvq7o6c:not(#\#):not(#\#){padding-inline:calc(var(--x11a6b6e) * 6)}
.x1bvjpef:not(#\#):not(#\#){text-decoration:underline}
.x61lnj5:not(#\#):not(#\#){transition:all .25s ease-in-out}
.xmqym13:not(#\#):not(#\#){transition:all .25s}
.x1f5mfz7:not(#\#):not(#\#){transition:border-color .25s}
.x1a0iw01:not(#\#):not(#\#){transition:opacity 2s ease-in-out,max-height .5s ease-in-out}
.x1od294v:focus-within:not(#\#):not(#\#){border-color:rgb(var(--x1cmvkv8))}
.xk2swo9:focus-visible:not(#\#):not(#\#){outline:none}
.x1bjj7zv:has([aria-selected="true"]):not(#\#):not(#\#){border-color:rgb(var(--x1d8o6m8))}
.x1leqdeu:focus-within:has([aria-selected="true"]):not(#\#):not(#\#){border-color:rgb(var(--x1d8o6m8))}
.xe7lalf:hover:not(#\#):not(#\#){border-color:rgb(var(--x16ly0dm))}
.xqf039g:hover:not(#\#):not(#\#){border-color:rgb(var(--x1cmvkv8))}
.x1h2mghu:focus:not(#\#):not(#\#){border-color:rgb(var(--x16ly0dm))}
.x1l5zm9h:focus:not(#\#):not(#\#){border-color:rgb(var(--x1cmvkv8))}
.xfpc920:focus:not(#\#):not(#\#){outline:0}
.x23ks55:has([aria-selected="true"]):hover:not(#\#):not(#\#){border-color:rgb(var(--x1d8o6m8))}
@media screen and (min-width: 37.5em){.x137cklm.x137cklm:not(#\#):not(#\#){padding-inline:calc(var(--x11a6b6e) * 4)}}
.x1rob05q:not(#\#):not(#\#):not(#\#){-webkit-text-size-adjust:100%}
.x1pha0wt:not(#\#):not(#\#):not(#\#){align-items:baseline}
.x6s0dn4:not(#\#):not(#\#):not(#\#){align-items:center}
.xpqajaz:not(#\#):not(#\#):not(#\#){align-items:end}
.x1y5e3q9:not(#\#):not(#\#):not(#\#){aspect-ratio:1 / 1}
.x1plog1:not(#\#):not(#\#):not(#\#){aspect-ratio:1}
.x7kf4j3:not(#\#):not(#\#):not(#\#){aspect-ratio:256 / 73}
.xl1tjh7:not(#\#):not(#\#):not(#\#){background-color:rgb(var(--x16ly0dm))}
.xt9kyki:not(#\#):not(#\#):not(#\#){background-color:rgb(var(--x17deri3))}
.x1m91rcv:not(#\#):not(#\#):not(#\#){background-color:rgba(var(--msg-color),.1)}
.x1hn5qym:not(#\#):not(#\#):not(#\#){background-color:rgba(var(--xqvssas),.1)}
.xjbqb8w:not(#\#):not(#\#):not(#\#){background-color:transparent}
.x1mwwwfo:not(#\#):not(#\#):not(#\#){border-collapse:collapse}
.x9f619:not(#\#):not(#\#):not(#\#){box-sizing:border-box}
.x1hyvwdk:not(#\#):not(#\#):not(#\#){clip-path:inset(50%)}
.xeh89do:not(#\#):not(#\#):not(#\#){clip:rect(0 0 0 0)}
.x11t3ix7:not(#\#):not(#\#):not(#\#){color:rgb(var(--x16ly0dm))}
.xjtx5ek:not(#\#):not(#\#):not(#\#){color:rgb(var(--x1h1bbff))}
.xlzeeia:not(#\#):not(#\#):not(#\#){color:rgb(var(--x5xmmt9))}
.x1k4x9k5:not(#\#):not(#\#):not(#\#){color:rgb(var(--xdp9xa9))}
.x1cgvqat:not(#\#):not(#\#):not(#\#){color:rgb(var(--xhog17l))}
.xfyyyql:not(#\#):not(#\#):not(#\#){color:rgb(var(--xqvssas))}
.x6jkvnd:not(#\#):not(#\#):not(#\#){color:rgb(var(--xvlok90))}
.xzgehkk:not(#\#):not(#\#):not(#\#){color:rgba(var(--x5xmmt9),.7)}
.xqk7nlj:not(#\#):not(#\#):not(#\#){column-gap:calc(var(--x11a6b6e) * 2)}
.x1oxd6qe:not(#\#):not(#\#):not(#\#){column-gap:calc(var(--x11a6b6e) * 3)}
.x14xop28:not(#\#):not(#\#):not(#\#){column-gap:calc(var(--x11a6b6e) * 4)}
.x7kns6u:not(#\#):not(#\#):not(#\#){column-gap:var(--hdr-gutter)}
.xt0e3qv:not(#\#):not(#\#):not(#\#){cursor:default}
.x1h6gzvc:not(#\#):not(#\#):not(#\#){cursor:not-allowed}
.x1ypdohk:not(#\#):not(#\#):not(#\#){cursor:pointer}
.x1lliihq:not(#\#):not(#\#):not(#\#){display:block}
.x78zum5:not(#\#):not(#\#):not(#\#){display:flex}
.xrvj5dj:not(#\#):not(#\#):not(#\#){display:grid}
.x3nfvp2:not(#\#):not(#\#):not(#\#){display:inline-flex}
.xbh8q5q:not(#\#):not(#\#):not(#\#){fill:none}
.xfp7yq:not(#\#):not(#\#):not(#\#){fill:rgb(var(--x16ly0dm))}
.xq1z78k:not(#\#):not(#\#):not(#\#){fill:rgb(var(--xqvssas))}
.x1a02dak:not(#\#):not(#\#):not(#\#){flex-wrap:wrap}
.xfhbvgl:not(#\#):not(#\#):not(#\#){font-family:var(--xx6uh78)}
.x1tva8pl:not(#\#):not(#\#):not(#\#){font-feature-settings:'kern' 1}
.xzi3nbc:not(#\#):not(#\#):not(#\#){font-kerning:normal}
.xk390pu:not(#\#):not(#\#):not(#\#){font-size:100%}
.x1frdq0f:not(#\#):not(#\#):not(#\#){font-size:calc(var(--x11a6b6e) * 20)}
.x4ajf0m:not(#\#):not(#\#):not(#\#){font-size:var(--x14eqgi1)}
.x12rtmos:not(#\#):not(#\#):not(#\#){font-size:var(--x16n6y39)}
.x1dfmuzi:not(#\#):not(#\#):not(#\#){font-size:var(--x1kw1fl2)}
.x12qpm81:not(#\#):not(#\#):not(#\#){font-size:var(--x1ufzsyk)}
.xwt6id:not(#\#):not(#\#):not(#\#){font-size:var(--x5bzz5b)}
.xsl5jwc:not(#\#):not(#\#):not(#\#){font-size:var(--x7ffph1)}
.xkrocyl:not(#\#):not(#\#):not(#\#){font-size:var(--x8ybgp0)}
.x9rr2gb:not(#\#):not(#\#):not(#\#){font-size:var(--xe2w5p4)}
.xb4rnpx:not(#\#):not(#\#):not(#\#){font-size:var(--xj1r1em)}
.xo1l8bm:not(#\#):not(#\#):not(#\#){font-weight:400}
.x1xlr1w8:not(#\#):not(#\#):not(#\#){font-weight:700}
.xx1abn8:not(#\#):not(#\#):not(#\#){grid-column-end:-1}
.xcrlgei:not(#\#):not(#\#):not(#\#){grid-column-start:1}
.x1xdureb:not(#\#):not(#\#):not(#\#){grid-column-start:2}
.x1wo2uxu:not(#\#):not(#\#):not(#\#){grid-column-start:main}
.x1v56fcy:not(#\#):not(#\#):not(#\#){grid-row-end:-1}
.x1agbcgv:not(#\#):not(#\#):not(#\#){grid-row-start:1}
.x13vxnyz:not(#\#):not(#\#):not(#\#){grid-row-start:2}
.x1nrnwdn:not(#\#):not(#\#):not(#\#){grid-template-columns:[margin-start] minmax(0,1fr) [main-start] minmax(0,50ch) [main-end] minmax(0,1fr) [margin-end]}
.x1p5u9e3:not(#\#):not(#\#):not(#\#){grid-template-columns:0 1fr 0}
.x1qxlq8j:not(#\#):not(#\#):not(#\#){grid-template-rows:auto auto 1fr}
.xtijo5x:not(#\#):not(#\#):not(#\#){inset-inline-end:0}
.x1o0tod:not(#\#):not(#\#):not(#\#){inset-inline-start:0}
.xl56j7k:not(#\#):not(#\#):not(#\#){justify-content:center}
.x1nhvcw1:not(#\#):not(#\#):not(#\#){justify-content:flex-start}
.x1lqcxt8:not(#\#):not(#\#):not(#\#){justify-self:start}
.x2ujxvm:not(#\#):not(#\#):not(#\#){letter-spacing:.05em}
.x3gokd0:not(#\#):not(#\#):not(#\#){line-height:1.5em}
.xo5v014:not(#\#):not(#\#):not(#\#){line-height:1}
.x1y2w5zr:not(#\#):not(#\#):not(#\#){line-height:calc(var(--x11a6b6e) * 7)}
.x18c9a31:not(#\#):not(#\#):not(#\#){line-height:var(--x1my5hdh)}
.xc17n18:not(#\#):not(#\#):not(#\#){line-height:var(--x1omkpnl)}
.x18ldoyc:not(#\#):not(#\#):not(#\#){line-height:var(--x6xkoxf)}
.xi2ej7p:not(#\#):not(#\#):not(#\#){line-height:var(--xbv692h)}
.x1tsfecp:not(#\#):not(#\#):not(#\#){line-height:var(--xlgulcz)}
.xbbpfqk:not(#\#):not(#\#):not(#\#){line-height:var(--xpeiiyp)}
.xu79kgk:not(#\#):not(#\#):not(#\#){line-height:var(--xveqbg)}
.xbnebdd:not(#\#):not(#\#):not(#\#){line-height:var(--xzhibpf)}
.xvc5jky:not(#\#):not(#\#):not(#\#){margin-inline-start:auto}
.x1cc1xoi:not(#\#):not(#\#):not(#\#){mix-blend-mode:color-burn}
.x133uxhx:not(#\#):not(#\#):not(#\#){mix-blend-mode:color}
.x1owg228:not(#\#):not(#\#):not(#\#){mix-blend-mode:multiply}
.x1ptxcow:not(#\#):not(#\#):not(#\#){opacity:.1}
.xvpkmg4:not(#\#):not(#\#):not(#\#){opacity:.25}
.xuzhngd:not(#\#):not(#\#):not(#\#){opacity:.3}
.xbyyjgo:not(#\#):not(#\#):not(#\#){opacity:.5}
.x197sbye:not(#\#):not(#\#):not(#\#){opacity:.6}
.x1ks1olk:not(#\#):not(#\#):not(#\#){opacity:.7}
.xg01cxk:not(#\#):not(#\#):not(#\#){opacity:0}
.x1hc1fzr:not(#\#):not(#\#):not(#\#){opacity:1}
.x1k57tk5:not(#\#):not(#\#):not(#\#){outline-width:0}
.x16gqus9:not(#\#):not(#\#):not(#\#){padding-inline-end:calc(var(--x11a6b6e) * 3)}
.x1me37m5:not(#\#):not(#\#):not(#\#){padding-inline-start:2em}
.x1pmam5e:not(#\#):not(#\#):not(#\#){padding-inline-start:calc(var(--x11a6b6e) * 3)}
.x47corl:not(#\#):not(#\#):not(#\#){pointer-events:none}
.x10l6tqk:not(#\#):not(#\#):not(#\#){position:absolute}
.x1n2onr6:not(#\#):not(#\#):not(#\#){position:relative}
.x1h6yn9r:not(#\#):not(#\#):not(#\#){row-gap:calc(var(--x11a6b6e) * 3)}
.x1dyzumb:not(#\#):not(#\#):not(#\#){row-gap:calc(var(--x11a6b6e) * 5)}
.x9h1tpm:not(#\#):not(#\#):not(#\#){row-gap:var(--x11a6b6e)}
.xkcv6ua:not(#\#):not(#\#):not(#\#){scroll-behavior:smooth}
.x1owpc8m:not(#\#):not(#\#):not(#\#){stroke-linecap:round}
.x1f6yumg:not(#\#):not(#\#):not(#\#){stroke-linejoin:round}
.xvlca1e:not(#\#):not(#\#):not(#\#){stroke-width:2}
.x73je2i:not(#\#):not(#\#):not(#\#){stroke:currentColor}
.x140o2bo:not(#\#):not(#\#):not(#\#){table-layout:fixed}
.x2b8uid:not(#\#):not(#\#):not(#\#){text-align:center}
.x5gd9ij:not(#\#):not(#\#):not(#\#){text-decoration-color:currentColor}
.xr5o5jz:not(#\#):not(#\#):not(#\#){text-decoration-color:transparent}
.x6ur5ct:not(#\#):not(#\#):not(#\#){text-decoration-style:solid}
.xei8ps7:not(#\#):not(#\#):not(#\#){text-decoration-thickness:0}
.xfqjyog:not(#\#):not(#\#):not(#\#){text-rendering:optimizeLegibility}
.xtvhhri:not(#\#):not(#\#):not(#\#){text-transform:uppercase}
.x5ve5x3:not(#\#):not(#\#):not(#\#){touch-action:none}
.x3oybdh:not(#\#):not(#\#):not(#\#){transform:scale(1)}
.x66nb2h:not(#\#):not(#\#):not(#\#){transform:translate(40%,var(--y-offset))}
.x5w7jty:not(#\#):not(#\#):not(#\#){transform:translate(40%,var(--y-offset2))}
.x135zp7m:not(#\#):not(#\#):not(#\#){transform:translateY(-0.07em)}
.xlshs6z:not(#\#):not(#\#):not(#\#){visibility:hidden}
.xnpuxes:not(#\#):not(#\#):not(#\#){visibility:visible}
.xuxw1ft:not(#\#):not(#\#):not(#\#){white-space:nowrap}
.xp5j34i:not(#\#):not(#\#):not(#\#){word-wrap:normal}
.x8knxv4:not(#\#):not(#\#):not(#\#){z-index:-1}
.x1vjfegm:not(#\#):not(#\#):not(#\#){z-index:1}
.xnmmtda:not([disabled]):not(#\#):not(#\#):not(#\#){cursor:pointer}
.xzs8od2:focus-within:not(#\#):not(#\#):not(#\#){opacity:.9}
.x19s8k7w:focus-visible:not(#\#):not(#\#):not(#\#){text-decoration-color:rgb(var(--x1h1bbff))}
.xq7k88y:hover:not(#\#):not(#\#):not(#\#){background-color:#fff}
.x11lku9l:hover:not(#\#):not(#\#):not(#\#){background-color:rgb(var(--xm75uez))}
.x1h13evb:hover:not(#\#):not(#\#):not(#\#){background-color:rgba(var(--x16ly0dm),.15)}
.xlw7ekl:hover:not(#\#):not(#\#):not(#\#){color:rgb(var(--x1h1bbff))}
.x45eyqp:hover:not(#\#):not(#\#):not(#\#){color:rgb(var(--xqvssas))}
.x18uuni6:hover:not(#\#):not(#\#):not(#\#){opacity:.9}
.x1o7uuvo:hover:not(#\#):not(#\#):not(#\#){opacity:1}
.x9ojkr9:hover:not(#\#):not(#\#):not(#\#){text-decoration-color:currentColor}
.xiuy6q4:hover:not(#\#):not(#\#):not(#\#){text-decoration-color:rgb(var(--xqvssas))}
.xx6stda:hover:not(#\#):not(#\#):not(#\#){text-decoration-color:transparent}
.x1yp5wxd:hover:not(#\#):not(#\#):not(#\#){text-decoration-thickness:1px}
.xyn6nq1:focus:not(#\#):not(#\#):not(#\#){background-color:#fff}
.x84s9ce:focus:not(#\#):not(#\#):not(#\#){background-color:rgb(var(--xm75uez))}
.xjvsowo:focus:not(#\#):not(#\#):not(#\#){background-color:rgba(var(--x16ly0dm),.15)}
.xyjc04t:focus:not(#\#):not(#\#):not(#\#){color:rgb(var(--x1h1bbff))}
.xo7o50e:focus:not(#\#):not(#\#):not(#\#){color:rgb(var(--xqvssas))}
.x1uxbtfk:focus:not(#\#):not(#\#):not(#\#){opacity:1}
.x1chaoku:focus:not(#\#):not(#\#):not(#\#){text-decoration-color:currentColor}
.x1ar2v7m:focus:not(#\#):not(#\#):not(#\#){text-decoration-color:rgb(var(--xqvssas))}
.x15spe28:focus:not(#\#):not(#\#):not(#\#){text-decoration-color:transparent}
.xhj1bdl:focus:not(#\#):not(#\#):not(#\#){text-decoration-thickness:1px}
.xrcxsp3:hover:not([disabled]):not([aria-selected]):not(#\#):not(#\#):not(#\#){background-color:rgba(var(--x16ly0dm),.15)}
.x1jplu5e:active:not(#\#):not(#\#):not(#\#){transform:scale(.95)}
.x1dvv8zq:focus:not([disabled]):not([aria-selected]):not(#\#):not(#\#):not(#\#){background-color:rgba(var(--x16ly0dm),.15)}
@media screen and (orientation: landscape){.xkz1anz.xkz1anz:not(#\#):not(#\#):not(#\#){aspect-ratio:1920 / 840}}
@media screen and (min-width: 48em){.x15vdv57.x15vdv57:not(#\#):not(#\#):not(#\#){font-size:var(--x8ybgp0)}}
@media all and (min-width:80em){.x10gbhwl.x10gbhwl:not(#\#):not(#\#):not(#\#){font-size:var(--xe2w5p4)}}
@media all and (min-width:80em){.x1oq0bol.x1oq0bol:not(#\#):not(#\#):not(#\#){font-size:var(--xjury5h)}}
@media screen and (min-width: 48em){.xke9ukj.xke9ukj:not(#\#):not(#\#):not(#\#){grid-column-start:3}}
@media screen and (min-width: 48em){.x1d889am.x1d889am:not(#\#):not(#\#):not(#\#){grid-row-start:1}}
@media screen and (min-width: 48em){.xpnwg2g.xpnwg2g:not(#\#):not(#\#):not(#\#){grid-template-columns:0 auto 1fr 0}}
@media screen and (max-width: 37.4375em){.x15cnam.x15cnam:not(#\#):not(#\#):not(#\#){inset-inline-end:var(--hdr-gutter)}}
@media all and (min-width:80em){.xwxejfo.xwxejfo:not(#\#):not(#\#):not(#\#){line-height:var(--x1lgoayu)}}
@media all and (min-width:80em){.x1jyatpk.x1jyatpk:not(#\#):not(#\#):not(#\#){line-height:var(--xpeiiyp)}}
@media screen and (min-width: 37.5em){.x1avmkvb.x1avmkvb:not(#\#):not(#\#):not(#\#){position:relative}}
.xugyvlv:not(#\#):not(#\#):not(#\#):not(#\#){border-bottom-color:rgba(var(--x1bfcbt7),.3)}
.x1q0q8m5:not(#\#):not(#\#):not(#\#):not(#\#){border-bottom-style:solid}
.xso031l:not(#\#):not(#\#):not(#\#):not(#\#){border-bottom-width:1px}
.x1ey2m1c:not(#\#):not(#\#):not(#\#):not(#\#){bottom:0}
.x5yr21d:not(#\#):not(#\#):not(#\#):not(#\#){height:100%}
.xlzyvqe:not(#\#):not(#\#):not(#\#):not(#\#){height:1em}
.xjm9jq1:not(#\#):not(#\#):not(#\#):not(#\#){height:1px}
.x1h9z03w:not(#\#):not(#\#):not(#\#):not(#\#){height:calc(8lh + 1ex)}
.x1ygcwnt:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:calc(-1 * var(--x11a6b6e) * 3)}
.x1yjz80j:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:calc(var(--x11a6b6e) * 1)}
.x1uvj0e9:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:calc(var(--x11a6b6e) * 12)}
.xsaeqgg:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:calc(var(--x11a6b6e) * 15)}
.x13d6m3m:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:calc(var(--x11a6b6e) * 3)}
.x1145v0f:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:calc(var(--x11a6b6e) * 6)}
.xgkgep8:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:calc(var(--x11a6b6e) * 8)}
.x1f5eyeq:not(#\#):not(#\#):not(#\#):not(#\#){margin-top:calc(var(--x11a6b6e) * 1)}
.xn73jwv:not(#\#):not(#\#):not(#\#):not(#\#){margin-top:calc(var(--x11a6b6e) * 10)}
.xoz190o:not(#\#):not(#\#):not(#\#):not(#\#){margin-top:calc(var(--x11a6b6e) * 3)}
.x1ie1431:not(#\#):not(#\#):not(#\#):not(#\#){margin-top:var(--hdr-top-padding)}
.xnjgh8c:not(#\#):not(#\#):not(#\#):not(#\#){max-height:0}
.x1961ds9:not(#\#):not(#\#):not(#\#):not(#\#){max-height:calc(var(--x11a6b6e) * 20)}
.x16xn7b0:not(#\#):not(#\#):not(#\#):not(#\#){max-width:100vw}
.x65f84u:not(#\#):not(#\#):not(#\#):not(#\#){max-width:500px}
.xq8v1hd:not(#\#):not(#\#):not(#\#):not(#\#){max-width:90%}
.xuxnkod:not(#\#):not(#\#):not(#\#):not(#\#){max-width:calc(var(--x11a6b6e) * 125)}
.xyn28ex:not(#\#):not(#\#):not(#\#):not(#\#){max-width:calc(var(--x11a6b6e) * 276)}
.x1us19tq:not(#\#):not(#\#):not(#\#):not(#\#){min-height:100%}
.x6ikm8r:not(#\#):not(#\#):not(#\#):not(#\#){overflow-x:hidden}
.x1e62n4j:not(#\#):not(#\#):not(#\#):not(#\#){padding-bottom:calc(var(--x11a6b6e) * 2)}
.x1l5a748:not(#\#):not(#\#):not(#\#):not(#\#){padding-bottom:calc(var(--x11a6b6e) * 3 - 1px)}
.x11hky74:not(#\#):not(#\#):not(#\#):not(#\#){padding-top:calc(var(--x11a6b6e) * 2)}
.x13vifvy:not(#\#):not(#\#):not(#\#):not(#\#){top:0}
.x1xlkln7:not(#\#):not(#\#):not(#\#):not(#\#){top:calc(var(--x11a6b6e) * 1)}
.xh8yej3:not(#\#):not(#\#):not(#\#):not(#\#){width:100%}
.x15vhz18:not(#\#):not(#\#):not(#\#):not(#\#){width:1em}
.x1i1rx1s:not(#\#):not(#\#):not(#\#):not(#\#){width:1px}
.xk2f0lv:not(#\#):not(#\#):not(#\#):not(#\#){width:2em}
.xu4yryv:not(#\#):not(#\#):not(#\#):not(#\#){width:calc(var(--x11a6b6e) * 190)}
@media screen and (max-width: 37.4375em){.x15pex5z.x15pex5z:not(#\#):not(#\#):not(#\#):not(#\#){margin-top:var(--x11a6b6e)}}
@media screen and (max-width: 37.4375em){.x3r4xqk.x3r4xqk:not(#\#):not(#\#):not(#\#):not(#\#){top:var(--hdr-top-padding)}}
@media all and (min-width:102em){.xrrl8ci.xrrl8ci:not(#\#):not(#\#):not(#\#):not(#\#){width:calc(var(--x11a6b6e) * 240)}}
.x1y9klss:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{border-radius:20px}
.x2ytyd3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{border-radius:calc(var(--x11a6b6e) * 1)}
.x15addbw:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{border-radius:var(--x11a6b6e)}
.x1096v4w:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{animation-duration:3.5s}
.xzuot31:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{animation-iteration-count:infinite}
.x15yn4rq:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{animation-name:xlkayg4-B}
.x1o22g4v:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{animation-timing-function:ease-in-out}
.x1kulc3m:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{aspect-ratio:.7}
.x1z0st2l:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{background-color:currentcolor}
.x1hfhjwi:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{background-color:currentColor}
.x1psiecr:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{background-color:rgba(var(--x16ly0dm),.3)}
.x17qoj4w:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{background-image:linear-gradient(180deg,rgba(var(--x17deri3),.97) 10%,transparent 45%)}
.x13ehj8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{background-image:radial-gradient(circle at 25% 35%,rgba(var(--x16ly0dm),.45) 0%,rgba(var(--xqvssas),.4) 125%)}
.x1izawyv:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{color:rgb(var(--x1d8o6m8))}
.xsm0u2b:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::placeholder{color:rgb(var(--xvlok90))}
.x1cpjm7i:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{content:""}
.x1s928wv:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{content:""}
.xvu9lmf:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{content:"*"}
.xhkezso:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{display:block}
.x1e1aq9x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{display:inline-block}
.x155w54b:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{font-size:var(--xe2w5p4)}
.xomy4te:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{margin-inline-start:calc(var(--x11a6b6e) * 4)}
.x4b0ijo:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{margin-inline-start:var(--x11a6b6e)}
.xkk1bqk:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{pointer-events:none}
.x2q1x1w:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{pointer-events:none}
.x1hmns74:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{position:absolute}
.x1j6awrg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{position:absolute}
.xwm0nlr:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{text-decoration-color:transparent}
.x1s3r70s:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{touch-action:none}
.x1hq8v4v:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{touch-action:none}
.x1jyfvxw:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{transform-origin:0 0}
.xdobayg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{transform:translate(-0.2em,-0.3em)}
.xaxty0f:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{transform:translateX(-100%)}
@media screen and (orientation: landscape){.x1gqo8ez.x1gqo8ez:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{background-image:linear-gradient(180deg,rgba(var(--x17deri3),.97) 7%,transparent 30%)}}
.xhq5o37:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{bottom:0}
.x1xrz1ek:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{bottom:0}
.xfqkdrx:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{height:1.4ex}
.xszcg87:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{height:100%}
.x48u2y7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{height:calc(var(--x11a6b6e) * 2)}
.x1wlytlt:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{left:0}
.x17cx49:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{left:0}
.xb0dqd5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{right:0}
.xnbfe2x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{right:0}
.x1y3wzot:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{top:0}
.x1m1drc7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{top:0}
.x1rmj1tg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::before{width:100%}
.xqysinb:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::after{width:3px}